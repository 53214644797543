(function () {
    "use strict";
    // @TODO, reprendre ca car ca doit planter :)

    // Focus input if Searchform is empty
    [].forEach.call( document.querySelectorAll( '.search-form' ), ( el ) => {
	el.addEventListener( 'submit', function ( e ) {
	    var search = el.querySelector( 'input' );
	    if ( search.value.length < 1 ) {
		e.preventDefault();
		search.focus();
	    }
	} );
    } );

    // Initialize Popovers: https://getbootstrap.com/docs/5.0/components/popovers
    var popoverTriggerList = [].slice.call( document.querySelectorAll( '[data-bs-toggle="popover"]' ) );
    var popoverList = popoverTriggerList.map( function ( popoverTriggerEl ) {
	return new bootstrap.Popover( popoverTriggerEl, {
	    trigger: 'focus',
	} );
    } );

    // get simple a cookie
    var getCookie = function (name) {
	var value = "; " + document.cookie;
	var parts = value.split("; " + name + "=");
	if (parts.length == 2) return parts.pop().split(";").shift();
    };

    
    jQuery( document ).ready(function($) {
	$('.swipe-close').click(function(){
	    $('.swipe-infos').toggleClass('close');
	    document.cookie = 'anim-contact=1; path=/; expires=Fri, 31 Dec 2024 23:59:59 GMT';	    
	});

	var cookieVal = getCookie('anim-contact'); 
	if (cookieVal != 1){
	    var t = 0;
	    var c = 0;
	    var windowWidth = $(window).width(); //retrieve current window width
	    if (windowWidth > 1159){
		$(window).scroll(function () {
		    t = $(window).scrollTop();
		    if (t < 900){
			c = 0.15*t + 50;
			$('.swipe-infos').css('top', c+'px');
			$('.swipe-infos').addClass('close');
		    }
		    else{
			$('.swipe-infos').removeClass('close');
		    }

		});
	    }
	}
    });
})();
